// scripts for all ajax querys //

jQuery(document).ready(function () {
    // Handle change on the dropdowns
    jQuery('#country-select, #zone-select, #state-select, #city-select').on('change', function () {
        fetchDistributors(1); // Load the first page when filters change
    });

    // Handle pagination click
    jQuery(document).on('click', '.ajax-pagination-link', function (e) {
        e.preventDefault();
        const page = jQuery(this).data('page');
        fetchDistributors(page);
    });

    function fetchDistributors(page) {
        const selectedCountry = jQuery('#country-select').val();
        const selectedZone = jQuery('#zone-select').val();
        const selectedState = jQuery('#state-select').val();
        const selectedCity = jQuery('#city-select').val();

        // Prepare data for AJAX request
        const data = {
            action: 'filter_distributors_by_country',
            country: selectedCountry,
            page: page, // Send page number for pagination
        };

        if (selectedCountry === 'india' && selectedZone && selectedZone !== 'Select Zone') {
            data.zone = selectedZone;
        }

        if (selectedState && selectedState !== 'Select State') {
            data.state = selectedState;
        }

        if (selectedCity && selectedCity !== 'Select City') {
            data.city = selectedCity;
        }

        // AJAX request
        jQuery.ajax({
            type: 'POST',
            url: ajax_obj.ajax_url,
            data: data,
            success: function (response) {
                jQuery("#all-results").hide();
                jQuery('#filtered-results').html(response);
            },
            error: function (xhr, status, error) {
                console.error('Error: ' + xhr.status);
            }
        });
    }

    // AJAX request to get states and cities based on the zone
    jQuery('#zone-select').on('change', function () {
        const zone = jQuery(this).val();
        if (zone) {
            fetchStatesCities(zone);
        }
    });

    function fetchStatesCities(zone) {
        const country = jQuery('#country-select').val();
        jQuery.ajax({
            type: 'POST',
            url: ajax_obj.ajax_url,
            data: {
                action: 'load_states_cities_by_zone',
                zone: zone,
                country: country
            },
            success: function (response) {
                if (response.success) {
                    // Populate states
                    const states = response.data.states;
                    const stateSelect = jQuery('#state-select');
                    stateSelect.empty();
                    stateSelect.append('<option>Select State</option>'); // Default option
                    states.forEach(state => {
                        stateSelect.append('<option value="' + state + '">' + state + '</option>');
                    });

                    // Populate cities
                    const cities = response.data.cities;
                    const citySelect = jQuery('#city-select');
                    citySelect.empty();
                    citySelect.append('<option>Select City</option>'); // Default option
                    cities.forEach(city => {
                        citySelect.append('<option value="' + city + '">' + city + '</option>');
                    });
                }
            }
        });
    }
});



document.addEventListener('DOMContentLoaded', function () {
    const countryDropdown = document.getElementById('country-select');
    const zoneDropdown = document.getElementById('zone-select');
    const stateDropdown = document.getElementById('state-select');
    const cityDropdown = document.getElementById('city-select');


    countryDropdown.addEventListener('change', function () {
        if (countryDropdown.value === 'india') {
            zoneDropdown.removeAttribute("disabled");
            stateDropdown.removeAttribute("disabled");
            cityDropdown.removeAttribute("disabled");

        }
    });
});

