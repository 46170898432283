wp.blocks.registerBlockType('tata-agrico/blog-content-image', {
    title: 'Blog Content Image',
    icon: 'format-image',
    category: 'media',
    edit: () => 'Dynamic content will be rendered on the frontend.',
    save: () => null,
});

wp.blocks.registerBlockType('tata-agrico/testimonial-query-loop', {
    title: 'Testimonial Query Loop',
    icon: 'format-image',
    category: 'media',
    edit: () => 'Dynamic content will be rendered on the frontend.',
    save: () => null,
});
