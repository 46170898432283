var $ = jQuery;

$( document ).ready(function() {
    $(".wp-block-query-pagination-next");

    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:20,
        responsiveClass:true,
        stageOuterClass:'owl-stage-outer product-slider-wrap',
        navText:['<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>'],
        responsive:{
            0:{
                items:1,
                nav:true,
                loop:false,
                dots:true,
                dotsData: true
            },
            600:{
                items:1,
                nav:true,
                loop:false,
                dots:true,
                dotsData: true
            },
            1000:{
                items:1,
                nav:true,
                loop:false,
                dots:true,
                dotsData: true
            }
        }
    });

});


// single products page image sticky on scroll
 
jQuery(document).ready(function ($) {
    let $imageGallery = $('.wp-block-woocommerce-product-image-gallery');
    let $descriptionCol = $('.description-col');
    let $imageCol = $('.image-col');

    let $descriptionColHeight = $('.description-col').outerHeight();

    if($descriptionColHeight > 520 ){
        updatePosition();
    }
    
   function updatePosition(){
    $(window).on('scroll', function () {
        let scrollTop = $(window).scrollTop();
        let imageTop = $imageCol.offset().top;
        let descriptionBottom = $descriptionCol.offset().top + $descriptionCol.outerHeight();
        let imageHeight = $imageGallery.outerHeight();
        
        if (scrollTop > imageTop && scrollTop + imageHeight < descriptionBottom) {
            $imageGallery.css({
                position: 'fixed',
                top: '0px',
                width: $imageCol.width(),
                transform: 'translateY(0)',
                transition: 'transform 0.1s ease-out'
            });
        } else if (scrollTop + imageHeight >= descriptionBottom) {
            $imageGallery.css({
                position: 'absolute',
                top: descriptionBottom - imageTop - imageHeight + 'px',
                transform: 'none'
            });
        } else {
            $imageGallery.css({
                position: 'relative',
                top: '0',
                transform: 'none'
            });
        }
    });

    // Ensure correct width on window resize
    $(window).on('resize', function () {
        $imageGallery.css('width', $imageCol.width());
    });
   }
});
